// import { useEffect, useRef } from 'react'
import { chakra, Icon } from '@chakra-ui/react'
// import { motion, useAnimation } from 'framer-motion'

// import useIntersectionObserver from 'hooks/useIntersectionObserver'
// import useMediaQuery from 'hooks/useMediaQuery'

// import breakpoints from 'constants/breakpoints'

interface ArtworkOrbitsProps {
  className?: string
}

const ArtworkOrbits = ({ className }: ArtworkOrbitsProps) => {
  return (
    <Icon
      className={className}
      mixBlendMode="lighten"
      pointerEvents="none"
      viewBox="0 0 1548 1862"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <path
          d="M772 1857.26c426.364 0 772-415.762 772-928.63C1544 415.762 1198.364 0 772 0S0 415.762 0 928.63c0 512.868 345.636 928.63 772 928.63Z"
          strokeOpacity=".2"
          stroke="#FFF"
          strokeDasharray="2"
        />
        <path
          d="M883.395 1588.56c364.842 0 660.605-295.46 660.605-659.93 0-364.47-295.763-659.93-660.605-659.93-364.842 0-660.605 295.46-660.605 659.93 0 364.47 295.763 659.93 660.605 659.93Z"
          strokeOpacity=".2"
          stroke="#FFF"
          strokeDasharray="2"
        />
        <path
          d="M1102.815 449.787c-242.832 0-439.686 196.854-439.686 439.686 0 242.832 196.854 439.686 439.686 439.686 242.832 0 439.686-196.854 439.686-439.686 0-242.832-196.854-439.686-439.686-439.686Z"
          strokeOpacity=".2"
          stroke="#FFF"
          strokeDasharray="2"
        />

        <circle stroke="#000" strokeWidth="7" fill="#59575F" r="7.5" cx="750" cy="630" />
        <circle stroke="#000" strokeWidth="7" fill="#59575F" r="7.5" cx="1360" cy="1245" />
      </g>
    </Icon>
  )

  // const ref = useRef()

  // const animation = useAnimation()
  // const animationReversed = useAnimation()

  // const entry = useIntersectionObserver(ref, {})
  // const isNarrowScreen = useMediaQuery(breakpoints.lg)

  // useEffect(() => {
  //   // TODO: check if we need this heavy dash animation
  //   return

  //   if (entry?.isIntersecting && !isNarrowScreen) {
  //     const transition = {
  //       duration: 0.5,
  //       ease: 'linear',
  //       repeat: Infinity,
  //     }

  //     animation.start({
  //       strokeDashoffset: [0, 4],
  //       transition,
  //     })

  //     animationReversed.start({
  //       strokeDashoffset: [0, -4],
  //       transition,
  //     })
  //   }

  //   return () => {
  //     animation.stop()
  //     animationReversed.stop()
  //   }
  // }, [entry, isNarrowScreen])

  // return (
  //   <Icon
  //     className={className}
  //     mixBlendMode="lighten"
  //     pointerEvents="none"
  //     viewBox="0 0 1548 1862"
  //     fill="none"
  //     fillRule="evenodd"
  //     ref={ref}
  //   >
  //     <g transform="translate(2 2)" fill="none" fillRule="evenodd">
  //       <motion.path
  //         animate={animation}
  //         d="M772 1857.26c426.364 0 772-415.762 772-928.63C1544 415.762 1198.364 0 772 0S0 415.762 0 928.63c0 512.868 345.636 928.63 772 928.63Z"
  //         strokeOpacity=".2"
  //         stroke="#FFF"
  //         strokeDasharray="2"
  //       />
  //       <motion.path
  //         animate={animationReversed}
  //         d="M883.395 1588.56c364.842 0 660.605-295.46 660.605-659.93 0-364.47-295.763-659.93-660.605-659.93-364.842 0-660.605 295.46-660.605 659.93 0 364.47 295.763 659.93 660.605 659.93Z"
  //         strokeOpacity=".2"
  //         stroke="#FFF"
  //         strokeDasharray="2"
  //       />
  //       <motion.path
  //         animate={animation}
  //         d="M1102.815 449.787c-242.832 0-439.686 196.854-439.686 439.686 0 242.832 196.854 439.686 439.686 439.686 242.832 0 439.686-196.854 439.686-439.686 0-242.832-196.854-439.686-439.686-439.686Z"
  //         strokeOpacity=".2"
  //         stroke="#FFF"
  //         strokeDasharray="2"
  //       />

  //       <motion.circle stroke="#000" strokeWidth="7" fill="#59575F" r="7.5">
  //         {entry?.isIntersecting && (
  //           <animateMotion
  //             dur="150s"
  //             repeatCount="indefinite"
  //             path="M1102.815 449.787c-242.832 0-439.686 196.854-439.686 439.686 0 242.832 196.854 439.686 439.686 439.686 242.832 0 439.686-196.854 439.686-439.686 0-242.832-196.854-439.686-439.686-439.686Z"
  //           />
  //         )}
  //       </motion.circle>

  //       <motion.circle stroke="#000" strokeWidth="7" fill="#59575F" r="7.5">
  //         {entry?.isIntersecting && (
  //           <animateMotion
  //             dur="150s"
  //             repeatCount="indefinite"
  //             path="M1102.815 1329.159c242.832 0 439.686-196.854 439.686-439.686 0-242.832-196.854-439.686-439.686-439.686-242.832 0-439.686 196.854-439.686 439.686 0 242.832 196.854 439.686 439.686 439.686Z"
  //           />
  //         )}
  //       </motion.circle>
  //     </g>
  //   </Icon>
  // )
}

export default chakra(ArtworkOrbits)
